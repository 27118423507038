import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Spinner from "../components/spinner";
import Utils from "../helpers/utils";

import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";

import KaleidoscopeColoring from "../components/kaleidoscope-coloring";

class NeverBefore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: undefined,
      variant: "Kaleidoscope",
      quality: 4,
      color: false,
      page: 1,
      submitted: false,
    };
  }

  getFieldUpdateFunction(fieldName) {
    return function (e) {
      let stateUpdate = {};
      if (e.target.type === "checkbox") {
        stateUpdate[fieldName] = e.target.checked;
      } else {
        stateUpdate[fieldName] = e.target.value;
      }
      this.setState(stateUpdate);
    }.bind(this);
  }

  changePageNumber(delta) {
    return function () {
      if (this.state.page + delta >= 1 && this.state.page + delta <= 50)
        this.setState({ page: this.state.page + delta });
    }.bind(this);
  }

  // TODO: use ref!
  downloadCanvas() {
    console.log("Download");
    let link = document.createElement("a");
    link.download = `pattern.png`;
    link.href = document.getElementById("canvas").toDataURL();
    link.click();
  }

  componentDidMount() {
    let queryObject = Utils.getQueryObj();
    if (queryObject.uniqueId !== undefined) {
      this.setState({
        uniqueId: Utils.invMakeBase64UrlSafe(queryObject.uniqueId),
      });
    } else if (queryObject.id !== undefined) {
      this.setState({
        uniqueId: Utils.invMakeBase64UrlSafe(queryObject.id),
      });
    } else {
      this.setState({ uniqueId: null });
    }

    if (
      (queryObject.uniqueId !== undefined || queryObject.id !== undefined) &&
      queryObject.variant !== undefined
    ) {
      this.setState({ submitted: true });
    }

    if (queryObject.variant !== undefined) {
      this.setState({ variant: queryObject.variant });
    } else {
      this.setState({ variant: null });
    }
  }

  render() {
    let inputHash = this.state.uniqueId;
    for (let i = 1; i < this.state.page; i++) {
      let lastChar = inputHash.slice(-1);
      console.log(lastChar);
      if (lastChar !== "=") {
        inputHash = Utils.invMakeBase64UrlSafe(inputHash) + "=";
      } else {
        inputHash = Utils.invMakeBase64UrlSafe(inputHash);
      }
      //console.log("HASH", inputHash);
      inputHash = Base64.stringify(sha256(inputHash));
    }

    let content = <Spinner></Spinner>;
    if (this.state.submitted === false) {
      content = (
        <div>
          <p>
            Please enter the <b>uniqueId</b> and <b>variant</b> of your coloring
            book. These can be found of the back cover.
          </p>
          <div>
            <label htmlFor="variant">Variant</label>
            <div>
              <input
                id="variant"
                name="variant"
                type="text"
                placeholder=""
                value={this.state.variant}
                disabled={true}
                onChange={function (e) {
                  this.setState({
                    variant: e.target.value,
                  });
                }.bind(this)}
              ></input>
            </div>
            <label htmlFor="uniqueId">UniqueId</label>
            <div>
              <input
                id="uniqueId"
                name="uniqueId"
                type="text"
                placeholder=""
                onChange={function (e) {
                  this.setState({
                    uniqueId: e.target.value,
                  });
                }.bind(this)}
              ></input>
            </div>
            <div>
              <button
                class="button"
                style={{ marginTop: "4px", marginBottom: "100%" }}
                onClick={function (e) {
                  this.setState({
                    submitted: true,
                  });
                }.bind(this)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      content = (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "4px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                UniqueId: <b>{Utils.makeBase64UrlSafe(this.state.uniqueId)}</b>
              </div>
              <div>
                Variant: <b>{this.state.variant}</b>
              </div>
            </div>
            <div>
              <button
                class="button"
                onClick={this.changePageNumber(-1)}
                style={{ marginRight: "4px" }}
              >
                {"<"}
              </button>
              Pattern{" "}
              <input style={{ width: "25px" }} value={this.state.page}></input>{" "}
              of 50
              <button
                class="button"
                onClick={this.changePageNumber(1)}
                style={{ marginLeft: "4px" }}
              >
                {">"}
              </button>
            </div>
          </div>

          <KaleidoscopeColoring
            variant={null}
            hash={inputHash}
            quality={this.state.quality}
            color={this.state.color}
            key={sha256(this.state.quality)} // Re-make the canvas when the quality changes
          ></KaleidoscopeColoring>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "4px",
            }}
          >
            <div>
              <input
                type="checkbox"
                id="color"
                name="color"
                onChange={this.getFieldUpdateFunction("color")}
                //checked={this.state.color}
              ></input>
              <label htmlFor="color"> Color it for me</label>
            </div>
            <div style={{ display: "flex" }}>
              <input
                id="quality"
                type="range"
                min="1"
                max="10"
                onChange={this.getFieldUpdateFunction("quality")}
                value={this.state.quality}
              ></input>
              <label
                for="quality"
                style={{
                  padding: "5px",
                }}
              >
                {" "}
                Image Quality
              </label>
            </div>

            <div>
              <button class="button" onClick={this.downloadCanvas}>
                Download Pattern Image
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Layout location={{ pathname: "/never-before" }} hideComments={true}>
        <SEO
          title="NeverBefore Coloring"
          description="Coloring pages generated from user input text"
        />
        <div
          className="inner-home post-list-wrapper"
          style={{ justifyContent: "center" }}
        >
          {content}
        </div>
      </Layout>
    );
  }
}

export default NeverBefore;
